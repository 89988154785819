/**
 * LanguageDropdownParts
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useState } from 'react';

import { toJS } from 'mobx';

import { ConfigStore } from '@/store/core/config';

import { SimpleCell } from '@exode.ru/vkui';
import { Dropdown, DropdownProps } from '@exode.ru/vkui/dist/unstable';

import { LanguageOptionsPart, LanguageSwitcher } from '@/components/Atoms/LanguageSwitcher';


interface Props {
    component?: Function;
    switcher?: ReactElement;
    componentProps?: Record<any, any>;
    dropdownProps?: Partial<DropdownProps>;
}


const LanguageDropdownPart = (props: Props) => {

    const {
        component = SimpleCell,
        componentProps = {},
        switcher = (
            <div>
                <LanguageSwitcher component={component} componentProps={componentProps}/>
            </div>
        ),
        dropdownProps = {
            offsetSkidding: 48,
            offsetDistance: -60,
            placement: ConfigStore.isDesktop ? 'left-start' : 'right-start',
        },
    } = props;

    const [ show, setShow ] = useState(false);

    return (
        <Dropdown shown={show} action="click" onShownChange={setShow} className="overflow-hidden" content={(
            <div onClick={() => setShow(false)}>
                <LanguageOptionsPart/>
            </div>
        )} {...toJS(dropdownProps)}>
            {switcher}
        </Dropdown>
    );
};


export { LanguageDropdownPart };
