/**
 * UseCourseManage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PlatformStore } from '@/store/platform';

import {
    CourseCountByTypeDocument,
    CourseFindManyManageDocument,
    CourseFindManyManageQuery,
    CourseType,
    CreateCourseInput,
    EducationSubject,
    ProductType,
    UpdateCourseInput,
    useCourseManageCloneMutation,
    useCourseManageCreateMutation,
    useCourseManageUpdateMutation,
} from '@/codegen/graphql';

import { useI18n, useI18nStatic } from '@/hooks/core';

import { useApolloClient } from '@apollo/client';

import { Storage } from '@/api/storage';
import { UserAuthStore } from '@/store/user/auth';

import { Notify } from '@/cutils';
import { courseTypeDeclension } from '@/types/course';
import { Router } from '@/services/Utils/Router';

import { Button } from '@exode.ru/vkui';

import { ManageCoursesListPageStore } from '@/pages/Manage/Courses/List/store';


export const useCourseManage = () => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('hooks.apollo.course');
    const { t: tStatic } = useI18nStatic('static.dictionary.declension.course');

    const apolloClient = useApolloClient();

    const courseDeclensionByType = courseTypeDeclension();

    const [ _updateCourse, {
        loading: updateCourseLoading,
        error: updateCourseError,
    } ] = useCourseManageUpdateMutation();

    const [ _createCourse, {
        loading: createCourseLoading,
        error: createCourseError,
    } ] = useCourseManageCreateMutation();

    const [ _cloneCourse, {
        loading: cloneCourseLoading,
        error: cloneCourseError,
    } ] = useCourseManageCloneMutation();

    const createCourse = (
        partial: Pick<CreateCourseInput,
            'name'
            | 'type'
            | 'subject'
            | 'contentCategoryId'
            | 'product'
        >,
    ) => _createCourse({
        variables: {
            course: {
                ...partial,
                tags: [],
                authors: [ UserAuthStore.user?.id as number ],
                description: t('describeWhoThisCourseFor', {
                    ns: 'hooks.apollo.course',
                    courseType: tStatic(`single.nominated.${partial.type}`).toLowerCase(),
                }),
            },
        },
        update: () => {
            apolloClient?.refetchQueries({
                include: [
                    CourseCountByTypeDocument,
                    CourseFindManyManageDocument,
                ],
            });
        },
        onCompleted: ({ courseManageCreate: { id } }) => {
            Router.replacePage(
                '/manage/course/:courseId([0-9]+)/navigator',
                { courseId: `${id}`, page: '1' },
            );
        },
        onError: (error) => {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: t('somethingWentWrong'),
            });
        },
    });

    const updateCourse = async (
        courseId: number,
        course: UpdateCourseInput,
    ) => await _updateCourse({
        variables: {
            course,
            courseId,
        },
        onError(err) {
            console.error(err);
        },
        onCompleted: () => {
            triggerUpdatePreviewEvent('course', courseId);
        },
    });

    const cloneCourse = async (
        sourceCourseId: number,
        sourceCourseType: CourseType,
    ) => {
        return _cloneCourse({
            variables: { courseId: sourceCourseId },
            onError: error => {
                console.error(error);

                Notify.vkui({
                    message: t('oopsSomethingWentWrongTryAgainLater'),
                    appearance: 'error',
                });
            },
            onCompleted: ({ courseManageClone: { id: cloneCourseId } }) => {
                Notify.vkui({
                    message: t('courseCopyIsCreated', {
                        courseType: courseDeclensionByType.single.parental[sourceCourseType].toLowerCase(),
                    }),
                    appearance: 'success',
                    vkuiOptions: {
                        layout: 'horizontal',
                        action: (
                            <Button mode="secondary" className="!bg-white !text-black">
                                {t('moveTo')}
                            </Button>
                        ),
                        onActionClick: () => Router.pushPage(
                            '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)',
                            { courseId: cloneCourseId.toString() },
                        ),
                    },
                });
            },
            update: (cache, { data }) => {
                const variables = ManageCoursesListPageStore.state;

                const cachedCourses = cache.readQuery<CourseFindManyManageQuery>({
                    query: CourseFindManyManageDocument,
                    variables,
                });

                if (!cachedCourses) {
                    return console.warn('[Cache]: cachedCourses отсутствуют в кэше');
                }

                const { count, pages, items } = cachedCourses.courseFindMany;

                cachedCourses && data && cache.writeQuery<CourseFindManyManageQuery>({
                    query: CourseFindManyManageDocument,
                    variables,
                    data: {
                        courseFindMany: {
                            pages,
                            count: count + 1,
                            items: [ data.courseManageClone, ...(items || []) ],
                        },
                    },
                });
            },
        });
    };

    const triggerUpdatePreviewEvent = (
        type: 'course',
        id: number | undefined | null,
    ) => {
        Storage.set('cms:event-stream', {
            type: 'preview:update-completed',
            payload: {
                id,
                type,
                timestamp: new Date(),
            },
        });
    };

    const createInitialValues = {
        name: '',
        subject: null as unknown as EducationSubject,
        contentCategoryId: null as unknown as number,
        product: {
            type: ProductType.Course,
            currency: COMMON?.organization.settings.currency,
        },
    };

    return {
        createCourse,
        createCourseError,
        createCourseLoading,
        updateCourse,
        updateCourseError,
        updateCourseLoading,
        cloneCourse,
        cloneCourseError,
        cloneCourseLoading,
        triggerUpdatePreviewEvent,
        createInitialValues,
    };
};
