/**
 * CoursePriceCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes } from 'react';

import { ShopStore } from '@/store/user/shop';

import { observer } from '@/pages/Core';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useI18n, useNative } from '@/hooks/core';

import { Router } from '@/services/Utils/Router';
import { CartService } from '@/services/User/Cart';

import { CourseFindOne } from '@/types/course';
import { ProductPriceLaunchPeriodMode } from '@/codegen/graphql';

import {
    Icon16Done,
    Icon16InfoOutline,
    Icon16ShoppingCartOutline,
    Icon32FavoriteCircleFillGreen,
    Icon32StarsCircleFillViolet,
} from '@vkontakte/icons';

import { Button, SimpleCell } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { ContentCard } from '@/components/Atoms/ContentCard';

import { gradient } from '@/assets/styles/modules/gradient';

import { PriceAmountPart } from './parts/PriceAmountPart';
import { PriceTagPart } from './parts/PriceTagPart';
import { PriceAvatarPart } from './parts/PriceAvatarPart';

import { Container } from './CoursePriceCard.styled';


export interface CoursePriceCardProps extends HTMLAttributes<HTMLDivElement> {
    productId: number;
    course: CourseFindOne;
    currency: NonNullable<CourseFindOne['product']>['currency'];
    price: Exclude<NonNullable<CourseFindOne['product']>['prices'], null | undefined>[number];
    width?: string;
    height?: string;
    flexColReverse?: boolean;
}


const CoursePriceCard = observer((props: CoursePriceCardProps) => {

    const {
        width,
        height,
        productId,
        currency,
        course,
        flexColReverse = false,
        price: {
            id,
            tags,
            icon,
            title,
            amount,
            actualTo,
            previousAmount,
            actualAccessDays,
            launchPeriodMode,
            amountExchangeRates,
            description: priceDescription,
        },
        ...rest
    } = props;

    const { t } = useI18n('components.Product.PriceCard');

    const { triggerHaptic } = useNative();

    const inCart = ShopStore.checkInCart(productId, id);

    const onClickAddToCart = async () => {
        triggerHaptic();

        await CartService.addToCart({
            productId,
            priceId: id,
            addedAt: new Date(),
        }, false);
    };

    const onClickProceedToCart = () => {
        Router.pushPage('/cart');
    };

    return (
        <Container width={width} height={height} {...rest} data-test="product.price-card" className="p-1 thin-border">
            <div onClick={!inCart ? onClickAddToCart : undefined}
                 className="z-20 rounded-2xl h-full flex flex-col cursor-pointer">
                <SimpleCell multiline
                            className="pointer-events-none items-start"
                            before={<PriceAvatarPart inCart={inCart} icon={icon}/>}
                            subtitle={(<span className="flex mt-1 text-primary">{priceDescription}</span>)}>
                    <span className="text-primary flex font-bold fs-12">
                        {title}
                    </span>

                    <div className="flex items-center">
                        <PriceAmountPart amount={amount}
                                         currency={currency}
                                         previousAmount={previousAmount}
                                         amountExchangeRates={amountExchangeRates}/>
                    </div>
                </SimpleCell>

                <If is={launchPeriodMode === ProductPriceLaunchPeriodMode.SelfDefinition}>
                    <div className="flex flex-wrap gap-2 px-4 mt-1 mb-2">
                        <TextTooltip offsetSkidding={8}
                                     placement="bottom-start"
                                     className="max-w-[180px]"
                                     text={t('priceActivityIsLimitedToSpecifiedDate')}>
                            <div className="min-w-[16px]">
                                <Icon16InfoOutline fill="var(--icon_secondary)"/>
                            </div>
                        </TextTooltip>

                        <span className="flex text-secondary fs-12">
                            {t('priceIsActiveTill', { date: Time.parseDate(actualTo, 'DD.MM.YYYY HH:mm') })}
                        </span>
                    </div>
                </If>

                <div className="mb-2"/>

                <div className={[ 'flex flex-col flex-1', flexColReverse ? 'flex-col-reverse' : '' ].join(' ')}>
                    <div className={[
                        'flex flex-col px-4 mb-4 gap-3',
                        flexColReverse ? 'mb-auto pb-4' : 'mt-1',
                    ].join(' ')}>
                        {[
                            ...tags.filter(e => !e.startsWith('-')),
                            ...tags.filter(e => e.startsWith('-')),
                        ].map((item, i) => <PriceTagPart key={i} tag={item}/>)}
                    </div>

                    <div className={[
                        'flex mx-4 mb-4',
                        !flexColReverse ? 'mt-auto' : '',
                    ].join(' ')}>
                        <ContentCard wholeCardActive
                                     mode="outline"
                                     className="w-full"
                                     subtitle={t('accessPeriod')}
                                     header={[ t('days', { count: actualAccessDays || 0 }) ].join(' ')}
                                     after={(
                                         <div>
                                             <If is={true}>
                                                 {(
                                                     inCart
                                                         ? <Icon32FavoriteCircleFillGreen width={40} height={40}/>
                                                         : (
                                                             <Icon32FavoriteCircleFillGreen width={40}
                                                                                            height={40}
                                                                                            style={{ filter: 'grayscale(1)' }}/>
                                                         )
                                                 )}
                                             </If>

                                             <If is={false}>
                                                 {(
                                                     inCart
                                                         ? <Icon32StarsCircleFillViolet width={40} height={40}/>
                                                         : (
                                                             <Icon32StarsCircleFillViolet width={40}
                                                                                          height={40}
                                                                                          style={{ filter: 'grayscale(1)' }}/>
                                                         )
                                                 )}
                                             </If>
                                         </div>
                                     )}/>
                    </div>

                    <div className="flex sticky bottom-2 bg-content button-rounded z-[101] items-end w-[calc(100%_-_32px)] mx-4 mb-4">
                        <Button size="l"
                                stretched
                                appearance="positive"
                                data-test="cart.payment.checkout"
                                mode={inCart ? 'primary' : 'outline'}
                                onClick={inCart ? onClickProceedToCart : undefined}
                                before={inCart ? <Icon16Done/> : <Icon16ShoppingCartOutline/>}
                                className={[
                                    'h-fit bg-gradient-to-br',
                                    inCart ? gradient.green500ToLime600 : '',
                                ].join(' ')}>
                            {inCart ? t('goToPayment') : t('selectPrice')}
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
});


export { CoursePriceCard };
