/**
 * Chat plurals
 *
 * @author: exode <hello@exode.ru>
 */

import plural from 'plural-ru';


/**
 * Склонение "письмо"
 * @param {number} length
 * @returns {string}
 */
export const pluralMail = (length: number) => (
    plural(length, 'письмо', 'письма', 'писем')
);
